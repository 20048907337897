import React from "react";
import {
  Image,
  ContactWrapper,
} from "./AboutElements";
import "animate.css/animate.min.css";


function About() {
  return (
    <ContactWrapper id="about">
      <svg
        height="100%"
        width="100%"
        id="svg"
        viewBox="0 0 1440 400"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 0,400 C 0,400 0,200 0,200 C 114.35714285714289,156.53571428571428 228.71428571428578,113.07142857142858 351,131 C 473.2857142857142,148.92857142857142 603.4999999999998,228.25 713,248 C 822.5000000000002,267.75 911.2857142857144,227.92857142857142 1029,210 C 1146.7142857142856,192.07142857142858 1293.3571428571427,196.03571428571428 1440,200 C 1440,200 1440,400 1440,400 Z"
          stroke="none"
          strokeWidth="0"
          fill="#151418ff"
          transform="rotate(-180 720 200)"
        ></path>
      </svg>
      <div className="Container">
        <div className="SectionTitle">About Me</div>
        <div className="BigCard">
          <Image src="/DDS_edited_01.jpg" alt="man-svgrepo" />
          <div className="AboutBio">
            Hi there! My name is <strong>Prabhat Tripathi</strong>. I'm based
            out of Lucknow. Ever since I was a kid, I have felt an immense love
            for computer gaming and new technologies which ultimately fueled my
            curiosity and made me enter the world of Computers and Tech. Apart
            from Gadgets, I love to travel and explore, I like Music (My Kind of
            Music) and maybe Waterparks whenever i get a chance. You can talk to
            me about Movies, Standup Comedy etc, I'm Somewhat Aware ;)
            <br />
            <br />
            I completed my
            post-graduation from Chandigarh University in the year 2023,
            where I gained in-depth knowledge and practical skills in various
            computer science domains.I am always eager to take on new challenges
            and explore new opportunities in the field of technology, especially
            in web development and mobile development. I enjoy working with
            others towards a common goal and learning from their perspectives
            and experiences. I Try to keep myself updated with all the latest
            updates in various Technology sectors around the globe.
            <br />
            <br />
            Apart from these, I enjoy sharing a great sense of humor with
            others. A great watch, and reading an article full of insights makes
            up for my entertainment. I also like to keep abreast of the latest
            developments in the world affairs and participate in constructive
            conversations with people from diverse cultures and viewpoints.
          </div>
        </div>
      </div>
    </ContactWrapper>
  );
}

export default About;
