import React from "react";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import { AiFillFacebook, AiFillInstagram, AiFillMail } from "react-icons/ai";
import styled from "@emotion/styled";

const SocialContainer = styled.div`
  position: fixed;
  top: 48%;
  left: 1.5rem;
  transform: translateY(-50%);

  ul {
    display: block;
  }

  .item + .item {
    margin-top: 1rem;
  }

  a {
    font-size: 2.3rem;
    color: rgb(119, 119, 121);
    &:hover {
      color: rgb(57, 134, 250);
    }
  }

  @media screen and (max-width: 1000px) {
    margin-top: 2rem;
    position: relative;
    top: 0;
    left: 0;
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
    }

    a {
      font-size: 2.5rem;
      color: #151418;
      &:hover {
        color: rgb(57, 134, 250);
      }
    }

    .item + .item {
      margin-top: 0;
      margin-left: 2rem;
    }
  }
`;
function FixSocialIcon() {
  return (
    <SocialContainer>
      <ul>
        <li className="item">
          <a
            href="https://www.linkedin.com/in/prabhat-tripathi-4117a6217?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
        </li>
        <li className="item">
          <a
            href="https://github.com/prabhattripathii"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub />
          </a>
        </li>
        <li className="item">
          <a
            href="https://www.facebook.com/prabhat.tripathi.18400700?mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiFillFacebook />
          </a>
        </li>
        <li className="item">
          <a
            href="https://www.instagram.com/prabhattripathii/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiFillInstagram />
          </a>
        </li>
        <li className="item">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:pra.tri.27@gmail.com"
          >
            <AiFillMail />
          </a>
        </li>
      </ul>
    </SocialContainer>
  );
}

export default FixSocialIcon;
