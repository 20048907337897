import React from "react";
import Hero from "../components/Hero/Hero";
import About from "../components/About/About";
import Contact from "../components/Contact/Contact";
import FixSocialIcon from "../components/MiniComponents/FixSocialIcon";
import Footer from "../components/Footer/Footer";
import ScrollToTop from "../components/MiniComponents/ScrollToTop";
import Skills from "../components/Skills/Skills";

function Home()
{
    return(
        <>
        <Hero/>
        <About/>
        <Skills/>
        <Contact/>
        <FixSocialIcon/>
        <Footer/>
        <ScrollToTop/>
        </>
    )
}

export default Home;