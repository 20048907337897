import React from "react";
import SkillCard from "./SkillComponent/SkillComponent";
import { ContactWrapper } from "./SkillElements";
function Skills() {
  return (
    <>
      <ContactWrapper id="skills">
        <div className="ProjectWrapper" id="projects">
          <div className="Container">
            <div className="SectionTitle">Skills</div>
            <SkillCard />
          </div>
        </div>
      </ContactWrapper>
    </>
  );
}

export default Skills;
