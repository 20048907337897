import React, { useState, useRef } from "react";
import { ContactWrapper, Email } from "./ContactElement";
import { MdContentCopy } from "react-icons/md";
import { IconButton, Tooltip } from "@mui/material";
import Zoom from "@mui/material/Zoom";
import {
  TextField,
  Container,
  Grid,
  Typography,
  Modal,
  Box,
} from "@mui/material";
import emailjs from "@emailjs/browser";
import Fade from "@mui/material/Fade";

function Contact() {
  const [showTooltip, setShowTooltip] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [mailSent, setMailSent] = React.useState(false);
  const mailform = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText("pra.tri.27@gmail.com");
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 700);
    } catch (e) {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 700);
    }
  };

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const validateForm = () => {
    let errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) {
      errors.email = "Email is required";
    } else {
      let emailPattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
      if (!formData.email.match(emailPattern)) {
        errors.email = "Please enter a valid email address";
      }
    }
    if (!formData.phone) errors.phone = "Phone is required";
    else if (formData.phone.length !== 10)
      errors.phone = "Phone number must be 10 digits";
    if (!formData.message) errors.message = "Message is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "",
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
    p: 4,
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      emailjs
        .sendForm(
          "service_qvxtuge",
          "template_b39kg5p",
          mailform.current,
          "A3xxkM0eGkr-RcXb3"
        )
        .then(
          (result) => {
            console.log(result.text);
            setMailSent(true);
            handleOpenModal();
            setFormData({
              name: "",
              email: "",
              phone: "",
              message: "",
            });
          },
          (error) => {
            console.log(error.text);
            handleOpenModal();
          }
        );
      console.log("Working");
    }
  };

  return (
    <ContactWrapper id="contact">
      <div className="Container">
        <div className="SectionTitle">Get In Touch</div>
        <div className="BigCard">
          <div className="SectionSubTitle">Mail At:</div>
          <Email>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "20px",
                rowGap: "10px",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <span>pra.tri.27@gmail.com</span>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                open={showTooltip}
                onClose={() => setShowTooltip(false)}
                title="Copied!"
                TransitionComponent={Zoom}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                placement="bottom"
              >
                <IconButton onClick={copyToClipboard}>
                  <MdContentCopy
                    size={25}
                    style={{ cursor: "pointer", color: "#151418" }}
                  />
                </IconButton>
              </Tooltip>
            </div>
            <a
              className="btn PrimaryBtn btn-shadow"
              href="mailto:pra.tri.27@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Send Email
            </a>
          </Email>
        </div>
        <div className="SectionDivTitle">Or</div>
        <div className="BigCard">
          <div className="SectionSubTitle">Send A Message:</div>
          <Container maxWidth="sm">
            <form ref={mailform} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={!!formErrors.name}
                    helperText={formErrors.name}
                    name="name"
                    label="Name"
                    fullWidth
                    variant="outlined"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                    name="email"
                    label="Email"
                    fullWidth
                    variant="outlined"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={!!formErrors.phone}
                    helperText={formErrors.phone}
                    name="phone"
                    label="Phone"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={!!formErrors.message}
                    helperText={formErrors.message}
                    name="message"
                    label="Message"
                    multiline
                    fullWidth
                    rows={4}
                    variant="outlined"
                    value={formData.message}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} paddingTop={5} width={"100%"}>
                  <button
                    type="submit"
                    className="btn PrimaryBtn btn-shadow"
                    style={{ marginTop: "2px", width: "100%" }}
                  >
                    Send Message
                  </button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </div>
      </div>

      <Modal
        keepMounted
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Fade in={open}>
          <Container display="flex">
            <Box sx={style}>
              <Typography variant="h6" component="h2">
                {mailSent === true
                  ? "Message Sent Successfully"
                  : "Something Went Wrong :("}
              </Typography>
              <button
                className="btn PrimaryBtn btn-shadow"
                style={{ marginTop: "2px", width: "100%" }}
                onClick={() => {
                  handleCloseModal();
                }}
              >
                {mailSent === true ? "Okay" : "Try Again"}
              </button>
            </Box>
          </Container>
        </Fade>
      </Modal>
    </ContactWrapper>
  );
}

export default Contact;
