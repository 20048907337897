import { CardAll } from "./SkillComponentElement";
import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import ProgressBar from "@ramonak/react-progress-bar";
import { AnimationOnScroll } from "react-animation-on-scroll";


const StyledCard = styled(Card)({
  width: "100%",
  display: "flex",
  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
  margin: "0.5rem",
  borderRadius: "15px",
  overflow: "hidden",
});
const StyledCardMedia = styled(CardMedia)({
  width: 150,
  height: 150,
  objectFit: "cover",
  justifySelf: "center",
  borderRadius: "15px",
});

function SkillCard() {
  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }


  const stackList = [
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/devicon/devicon-original-wordmark.svg",
      name: "Full Stack Development",
      competence: 90,
    },
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
      name: "ReactJS",
      competence: 80,
    },

    {
      img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
      name: "Python",
      competence: 90,
    },
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/django/django-plain.svg",
      name: "Django",
      competence: 90,
    },
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
      name: "HTML",
      competence: 90,
    },
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg",
      name: "CSS",
      competence: 80,
    },
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
      name: "JavaScript",
      competence: 80,
    },
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
      name: "Typescript",
      competence: 70,
    },
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg",
      name: "Git",
      competence: 70,
    },
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
      name: "NodeJS",
      competence: 80,
    },
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg",
      name: "MongoDB",
      competence: 75,
    },
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
      name: "Java",
      competence: 70,
    },
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original.svg",
      name: "MySQL",
      competence: 80,
    },
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/bootstrap/bootstrap-original.svg",
      name: "Bootstrap",
      competence: 90,
    },
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/materialui/materialui-original.svg",
      name: "Material UI",
      competence: 80,
    },
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/express/express-original.svg",
      name: "Express",
      competence: 80,
    },
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/figma/figma-original.svg",
      name: "Figma",
      competence: 60,
    },
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/6910f0503efdd315c8f9b858234310c06e04d9c0/icons/tailwindcss/tailwindcss-original.svg",
      name: "TailwindCSS",
      competence: 90,
    },
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/55609aa5bd817ff167afce0d965585c92040787a/icons/angularjs/angularjs-original.svg",
      name: "Angular",
      competence: 70,
    },
    {
      img: "https://raw.githubusercontent.com/devicons/devicon/6910f0503efdd315c8f9b858234310c06e04d9c0/icons/azuredevops/azuredevops-original.svg",
      name: "DevOps",
      competence: 75,
    }
  ];

  return (
    <>
      <CardAll>
        <Grid container spacing={5}>
          {stackList.map((list, index) => (
            <>
              <StyledCard>
                <StyledCardMedia
                  component="img"
                  image={list.img}
                  alt="random image"
                />
                <Box
                  key={index}
                  p={2}
                  flexGrow={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <AnimationOnScroll
                    initiallyVisible={true}
                    animateIn="animate__flip"
                  >
                    <Typography variant="h6">{list.name}</Typography>
                    <ProgressBar
                      completed={list.competence}
                      bgColor={getRandomColor()}
                      borderRadius={"5px"}
                      labelAlignment="outside"
                      labelColor="black"
                      animateOnRender={true}
                    />
                  </AnimationOnScroll>
                </Box>
              </StyledCard>
            </>
          ))}
        </Grid>
      </CardAll>
    </>
  );
}

export default SkillCard;
