import React, { useState } from "react";
import {
  HeroContainer,
  HeroWrapper,
  HeroLeft,
  HeroRight,
  Image,
} from "./HeroElements";
import Dropdown from "../Dropdown/Dropdown";
import Header from "../Header/Header";
import { TypeAnimation } from "react-type-animation";
import "animate.css/animate.min.css";

function Hero() {
  const [isOpen, setIsOpen] = useState(false);
  const [showSubtitle, setShowSubtitle] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <main>
        <Dropdown isOpen={isOpen} toggle={toggle} />
        <Header toggle={toggle} />
        <HeroContainer>
          <HeroWrapper>
            <HeroLeft>
              <TypeAnimation
                cursor={false}
                sequence={["Hi, I'm Prabhat.", () => setShowSubtitle(true)]}
                speed={{ type: "keyStrokeDelayInMs", value: 150 }}
                wrapper="h1"
                repeat={0}
              />
              {showSubtitle && (
                <TypeAnimation
                  cursor={true}
                  sequence={[
                    500,
                    "A Full-Stack Developer.",
                    1000,
                    "A Software Programmer.",
                    1000,
                    "A Chandigarh University Post Graduate.",
                    1000,
                    "A Tech Enthusiast.",
                    1000,
                    "An Innovative Thinker.",
                    1000,
                    "A Gamer by Hobby.",
                    1000,
                    "A Cool Guy ;)",
                    1300,
                    "Go Ahead! Scroll Down to See More.",
                    1000,
                    "Go Check Out the Entire Website, Its really Awesome!!",
                    1000,
                    "Ok, this has been fun, but the Loop's Gonna Restart Now!",
                    1000,
                    "See ya! :)",
                    500,
                  ]}
                  speed={50}
                  deletionSpeed={65}
                  wrapper="h5"
                  repeat={Infinity}
                />
              )}
            </HeroLeft>
            <HeroRight>
              <Image src="/prabhattripathi home.svg" alt="man-svgrepo" />
            </HeroRight>
          </HeroWrapper>
        </HeroContainer>
      </main>
    </>
  );
}

export default Hero;
