import styled from "@emotion/styled";

export const ContactWrapper = styled.div`
  margin-top: 0rem;
`;

export const Image = styled.img`
  max-width: 120px;
  margin: 0 auto;
  margin-bottom: 1rem;
`;

